<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </div>
    </v-col>
    <template v-else>
      <v-btn
        color="primary"
        to="/ratings/new">
        Neue Bewertung
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="ratings"
        :loading="loading"
        sort-by="id"
        :sort-desc="true"
        class="elevation-1 mt-4"
        disable-pagination
        hide-default-footer
      >
        <template
          slot="item"
          slot-scope="props">
          <tr
            class="-clickable"
            @click="openRatingEditPage(props.item.id)">
            <td>{{ props.item.id }}</td>
            <td @click.prevent="openOrderDetailPage(props.item.order_id)">{{ props.item.order_id || '–' }}</td>
            <td>{{ props.item.product_name }}</td>
            <td>{{ props.item.rating }}</td>
            <td>{{ props.item.customer_name }}</td>
            <td>{{ props.item.rating_description }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Order ID',
          sortable: true,
          value: 'order_id',
        },
        {
          text: 'Produkt',
          sortable: true,
          value: 'product_name',
        },
        {
          text: 'Bewertung',
          sortable: true,
          value: 'rating',
        },
        {
          text: 'Kundenname',
          sortable: true,
          value: 'customer_name',
        },
        {
          text: 'Bewertungstext',
          sortable: true,
          value: 'rating_description',
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('rating/fetchRatings')
    this.$store.dispatch('product/fetchRentProducts')
  },
  computed: {
    ...mapGetters({
      rentProducts: 'product/rentProducts',
      loading: 'rating/loading',
    }),
    ratings() {
      const ratings = JSON.parse(JSON.stringify(this.$store.getters['rating/ratings']))

      return ratings.map((rating) => {
        rating.product_name = this.getProductName(rating.product_id)
        return rating
      })
    },
  },
  methods: {
    openOrderDetailPage(id) {
      if (!id) {
        return
      }
      this.$router.push(`/orders/${id}`)
    },
    openRatingEditPage(id) {
      this.$router.push(`/ratings/${id}`)
    },
    getProductName(productId) {
      return this.$store.getters['product/productName'](productId)
    },
  },
}
</script>
